/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Button, Hidden, Link, makeStyles, Typography } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    bottom: 0,
    width: '100%',
    display: 'flex',
    padding: theme.spacing(4, 10),
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(6, 0),
      justifyContent: 'center',
    },
  },
  nextButton: {
    marginLeft: 'auto',
    height: 53,
    width: 143,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      maxWidth: 250,
      width: '100%',
    },
  },
  nextButtonTrustedForm: {
    paddingRight: 42,
    color: 'white',
    boxShadow: 'none',
    fontSize: 16,
    minWidth: 64,
    boxSizing: 'border-box',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Lato',
    fontWeight: 700,
    lineHeight: 1.75,
    borderRadius: 4,
    textTransform: 'none',
    boxShadow: 'none',
    borderStyle: 'none',
    marginLeft: 'auto',
    height: 53,
    width: 143,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      maxWidth: 250,
      width: '100%',
    },
  },
  nextGoToDashboard: {
    marginLeft: 'auto',
    height: 53,
    width: 192,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      maxWidth: 250,
      width: '100%',
    },
  },
  skipButton: {
    margin: 'auto',
    color: theme.palette.primary.main,
    height: 53,
    width: 143,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxWidth: 250,
      width: '100%',
    },
  },
  backButton: {
    marginRight: 'auto',
    height: 53,
    width: 143,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: 'rgb(29, 105, 128) !important'
    }
  },
  link: {
    cursor: 'pointer',
  }
}));

export const BackButton = ({ onClick, mobile }) => {
  const classes = useStyles();

  return (
    <Button color="primary" className={classes.backButton} startIcon={<ArrowBack style={{ marginRight: 14 }} />} onClick={onClick}>
      Back
    </Button>
  );
};

export const NextButton = ({ onClick, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      className={classes.nextButton}
      variant="contained"
      color="primary"
      endIcon={<ArrowForward style={{ marginLeft: 14 }} />}
      onClick={onClick}
      disabled={disabled}
    >
      Next
    </Button>
  );
};

export const NextButtonTrustedForm = ({ onClick, disabled, arrowImage, arrowImageDisabled }) => {
  const classes = useStyles();

  useEffect(() => {
    const buttonLabelElement = document.getElementById('next-button');

    buttonLabelElement.style.background = disabled ? arrowImageDisabled : arrowImage;
    buttonLabelElement.style.backgroundPosition = 'right 30px bottom 50%';
    buttonLabelElement.style.backgroundRepeat = 'no-repeat';
  }, [disabled]);

  return (
    <div
      className={(disabled) ? null : classes.buttonHover}
      style={{marginLeft: 'auto', borderRadius: 4, backgroundColor: ((disabled) ? 'rgba(0, 0, 0, 0.12)' : '#2a97b7')}}
    >
      <button
        id="next-button"
        data-tf-element-role="submit"
        type="submit"
        className={classes.nextButtonTrustedForm}
        onClick={onClick}
        disabled={disabled}
        style={(disabled) ? {color: 'rgba(0, 0, 0, 0.26)'} : {color: 'white'}}
      >
        Next
      </button>
    </div>
  );
};

export const SkipButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.skipButton} onClick={onClick}>
      Skip
    </Button>
  );
};

export const FinishButton = ({ onClick, disabled, label }) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      className={classes.nextButton}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {label ? label : 'Finish'}
    </Button>
  );
};

export const GoToDashboardButton = ({ onClick, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      className={classes.nextGoToDashboard}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      Go to dashboard
    </Button>
  );
};

export const HelpLink = () => {
  const classes = useStyles();
  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').getElementsByTagName('iframe');

    if (elements.length < 1) {
      return;
    }

    elements[0].contentWindow.document.getElementsByTagName("button")[0].click();
  }

  return (
    <Typography variant="h5" align="center" noWrap paragraph>
      Having troubles? <Link className={classes.link} onClick={openHelpChat}>Get help</Link>
    </Typography>
  );
};

export default function StepsNavigation({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        React.Children.map(children, child => {
          const type = child.type?.displayName
            ? child.type.displayName
            : child?.type;

          switch (type) {
            case 'BackButton':
            case BackButton:
              return child;
            case 'SkipButton':
            case SkipButton:
              return child;
            case 'NextButton':
            case NextButton:
            case 'NextButtonTrustedForm':
            case NextButtonTrustedForm:
              return child;
            case 'FinishButton':
            case FinishButton:
              return child;
            case 'GoToDashboardButton':
            case GoToDashboardButton:
              return child;
            default:
              return null;
          }
        })
      }
      <Hidden mdUp>
        <HelpLink />
      </Hidden>
    </div>
  );
}
