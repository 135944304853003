/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Autocomplete } from '@material-ui/lab';
import { Link, makeStyles, Button, Typography, Grid, FormControl, TextField, FormHelperText } from '@material-ui/core';

import api from 'src/api/onboarding';

import CongratulationIcon from 'src/assets/icons/step4_congratulations.svg';
import Alert from 'src/components/alert';
import { CustomPaper } from 'src/components/autocomplete';
import PhoneInput from 'src/components/phoneInput';
import { currentStep, previousStep } from 'src/features/onboarding/slice';
import StepsNavigation, { FinishButton, BackButton } from 'src/features/onboarding/stepsNavigation';


const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 700,
    margin: 'auto',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  // buttonContainer: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   '& > *:fist-child': {
  //     marginRight: 10,
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     flexDirection: 'column',
  //     alignItems: 'center',
  //   },
  // },
  buttonDanger: {
    color: '#ce3100',
    borderColor: '#ce3100',
  },
  button: {
    height: 53,
    // maxWidth: 250,
  },
  button2: {
    maxWidth: 143,
    height: 53,
    marginTop: 7,
  },
  button3: {
    background: '#FFFFFF',
    border: '2px solid #2A97B7',
    borderRadius: 3,
    color: '#2A97B7',
    fontSize: 14,
    height: 53,
    width: 228,
    textAlign: 'center',
  },
  '@keyframes myEffect': {
    to: {
      clipPath: 'inset(0 -1ch 0 0)',
    },
  },
  search: {
    color: '#000000',
    background: '#B2EDD6',
    cursor: 'none',
    pointerEvents: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 10),
  },
  findLeadsSelect: {
    display: 'block',
    marginTop: '24px',

    '& .MuiInputBase-input': {
      width: 'auto',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      minHeight: '56px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 20px) scale(1)',
    },
    '& .MuiInputLabel-marginDense.MuiFormLabel-filled, & .MuiInputLabel-marginDense.Mui-focused': {
      transform: 'translate(14px, -6px) scale(0.75)',
    }
  },
  textBlack: {
    color: 'black'
  },
  noBackground: {
    '& div': {
      background: 'none',
    }
  },
  paragaph: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#F6F6F6',
    border: '1px solid #BBBCC3',
    borderRadius: '10px',
    padding: '21px',
    marginBottom: '20px',
  },
  paragaphTitle: {
    width: '15%',
  },
  paragaphText: {
    width: '85%',
  },
  paragaphForm: {
    width: 549,
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    margin: '20px auto 0px',
    padding: 36,
  },
  paragaphFormBackground: {
    background: '#B2EDD6',
    paddingTop: 20,
  },
  textBold: {
    fontWeight: 700,
  },
  textAnimation: {
    clipPath: 'inset(0 1ch 0 0)',
    animation: `$myEffect 1s steps(4) infinite`,
  }
}));

const getStoredValues = (value) => {
  if (typeof window !== 'undefined') {
    const values = JSON.parse(sessionStorage.getItem('step4'));

    return values && values[value];
  }

  return null;
};

const Step4 = props => {
  let timer = null;

  const classes = useStyles();

  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(false);
  const [findLeads, setFindLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [showFindLeads, setShowFindLeads] = useState(false);
  const [showFindLeadsResult, setShowFindLeadsResult] = useState(false);
  const [testCall, setTestCall] = useState(false);

  const formik = useFormik({
    initialValues: {
      countryCodeId: getStoredValues('countryCodeId') || '',
      phoneNumber: getStoredValues('phone') || '',
      lead: {
        id: null,
      },
    },
    validationSchema: yup.object().shape({
      countryCodeId: yup.string().required('This field is required.'),
      phone: yup.string().required('This field is required.'),
      phoneNumber: yup.string()
        .when(['countryCodeId', 'phone'], (countryCodeId, phone, schema) => {
          if (countries.length < 1) {
            return schema;
          }

          const country = countries.find(countryItem => countryItem.country_code === countryCodeId);

          if (country?.reg_pattern) {
            const pattern = country.reg_pattern.replace('/^', '^').replace('$/', '$');

            return schema.matches(new RegExp(pattern), 'Incorrect phone number.');
          }

          return schema;
        })
        .required('This field is required.'),
      lead: yup.object().shape({
        id: yup.number().required(),
      }),
    }),
    onSubmit: async values => {
      const { status, data, message } = await api.testCall(values);

      setTestCall(true);

      if (status === 'success') {
        setTimeout(() => {
          setShowFindLeadsResult(true);
          setTestCall(false);
        }, 5000);
      } else {
        setError(message);
        setShowFindLeadsResult(false);
        setSuccessShow(false);
        setErrorShow(false);

        setTimeout((() => {
          setError(false);
          setTestCall(false);
        }), 5000);
      }
    },
  });

  const handleChangePhone = (fieldName, value, countryCodeId, phone) => {
    formik.setValues({ ...formik.values, phoneNumber: value, countryCodeId, phone }, false);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 0) {
      return true;
    }

    setCountries(data);

    return true;
  };

  const handleChangeLead = (event, data) => {
    formik.setTouched({ ...formik.touched, lead: { id: true } }, false);
    formik.setFieldValue('lead', { id: data.id }, true);
  };

  const handleFindLeads = async () => {
    setIsLoading(true);
    setShowFindLeads(true);

    const { status, data, message } = await api.findLeads({});

    if (timer !== null) {
      clearTimeout(timer);
    }

    if (status === 'success' && data.length > 0) {
      setIsLoading(false);
      setFindLeads(data);
    } else if (status === 'success') {
      timer = setTimeout(() => {
        handleFindLeads();
      }, 10000);
    } else {
      setError(message);
    }
  };

  const handleFinish = () => {
    navigate('/me/overview/');
  };

  const handlePrevious = () => {
    props.previousStep();
  };

  useEffect(() => {
    props.currentStep({ activeStep: 4 });
  }, []);

  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').getElementsByTagName('iframe');

    if (elements.length < 1) {
      return;
    }

    elements[0].contentWindow.document.getElementsByTagName("button")[0].click();
  }

  return (
    <>
      <div className={classes.container}>
        <Grid container>
          <Typography variant="h1">Test lead connections</Typography>

          <Grid container justifyContent="space-between" className={classes.paragaph}>
            <Grid item className={classes.paragaphTitle}>Step 1:</Grid>

            <Grid item className={classes.paragaphText}>
              You’ll need to have <span className={classes.textBold}>connected at least one lead source/app</span> on the previous page. If not, <Link style={{ cursor: 'pointer' }} underline="none" onClick={handlePrevious}>go back and connect lead</Link>.
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" className={classes.paragaph}>
            <Grid item className={classes.paragaphTitle}>Step 2:</Grid>

            <Grid item className={classes.paragaphText}>
              <span className={classes.textBold}>Submit a fake lead with a working phone number</span> to the app/lead source you have connected to.
            </Grid>
          </Grid>

          <Grid container>
            <Grid container justifyContent="space-between" className={classes.paragaph}>
              <Grid item className={classes.paragaphTitle}>Step 3:</Grid>

              <Grid className={classes.paragaphText}>
                <Grid item>
                  Once you have submitted a fake lead. Click on ‘Find lead’.
                </Grid>

                <Grid item>
                  <Button
                    className={isLoading === true ? clsx(classes.button2, classes.search) : classes.button2}
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={handleFindLeads}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isLoading === true
                        ? <span className={classes.textAnimation}>Searching...</span>
                        : (showFindLeads === true
                          ? 'Reload leads'
                          : 'Find lead')
                    }
                  </Button>
                </Grid>
              </Grid>

            </Grid>

          </Grid>

          {
            (showFindLeads === true && isLoading === false)
              ? (
                <Grid container className={classes.paragaph}>
                  <Grid container justifyContent="space-between">
                    <Grid item className={classes.paragaphTitle}>Step 4:</Grid>

                    <Grid item className={classes.paragaphText}>
                      <span className={classes.textBold}>We’ve found a lead!</span> You can now run the test.
                    </Grid>
                  </Grid>

                  <Grid container>
                    <form className={classes.paragaphForm}>
                      <Autocomplete
                        className={classes.findLeadsSelect}
                        options={findLeads}
                        getOptionLabel={
                          option => `${option.firstName ? option.firstName : ''} ${option.lastName ? option.lastName : ''}: ${option.Phone1 ? option.Phone1 : ''}`
                        }
                        disableClearable
                        PaperComponent={CustomPaper}
                        onChange={handleChangeLead}
                        renderOption={option => (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                              <Typography>
                                {
                                  `${option.firstName ? option.firstName : ''} ${option.lastName ? option.lastName : ''}: ${option.Phone1 || option.Phone2 || option.Phone3 || option.Phone4}`
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            label="Choose test lead connection"
                            variant="outlined"
                            color="secondary"
                            name="lead.id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.lead && formik.touched.lead}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />

                      <div style={{ margin: 30 }} />

                      <Typography variant="subtitle1" className={classes.textBlack}>
                        Enter a phone number you can answer now. This will simulate the <span className={clsx(classes.textBold, classes.textBlack)}>&#39;rep&#39; phone</span>.
                      </Typography>

                      <FormControl variant="outlined" fullWidth margin="normal">
                        <PhoneInput
                          country
                          name="phoneNumber"
                          value={{
                            code: formik.values.countryCodeId,
                            value: formik.values.phoneNumber,
                          }}
                          countriesList={handleGetCountriesList}
                          onChange={handleChangePhone}
                          onBlur={formik.handleBlur}
                          error={
                            (formik.errors.phoneNumber && formik.touched.phoneNumber)
                            || formik.errors.phone
                            || formik.errors.countryCodeId
                          }
                        />

                        {
                          (formik.errors.phoneNumber && formik.touched.phoneNumber)
                            ? (<FormHelperText component="div" className={classes.textHelper}>
                              <Alert severity="error">{formik.errors.phoneNumber}</Alert>
                            </FormHelperText>)
                            : null
                        }
                      </FormControl>

                      <div style={{ margin: 30 }} />

                      <Grid container >
                        {
                          (testCall === false)
                            ? (
                              <Button
                                style={{ margin: '0 auto' }}
                                fullWidth
                                className={classes.button2}
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={formik.submitForm}
                              >
                                Test now
                              </Button>
                            )
                            : (
                              <Button
                                style={{ margin: '0 auto' }}
                                fullWidth
                                className={clsx(classes.button2, classes.search)}
                                variant="contained"
                                color="primary"
                                disableElevation
                              >
                                <span className={classes.textAnimation}>Calling...</span>
                              </Button>
                            )
                        }
                      </Grid>

                      {
                        error !== false && (<Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Alert severity="error">{error}</Alert>
                          </Grid>
                        </Grid>)
                      }
                    </form>

                    {
                      showFindLeadsResult === true
                        ? (
                          <Grid container className={clsx(classes.paragaphForm, classes.paragaphFormBackground)}>
                            <Grid container>
                              <Typography variant="h4" style={{ marginBottom: 13, }}>Did your phone ring?</Typography>
                            </Grid>

                            <Grid container justifyContent="space-between" alignItems="center">
                              <Button
                                fullWidth
                                disableElevation
                                color="primary"
                                variant="contained"
                                className={classes.button3}
                                onClick={() => {
                                  setSuccessShow(true);
                                  setErrorShow(false);
                                }}
                              >
                                Yes my phone rang
                              </Button>

                              <Button
                                fullWidth
                                variant="outlined"
                                className={classes.button3}
                                classes={{ outlined: classes.buttonDanger }}
                                onClick={() => {
                                  setErrorShow(true);
                                  setSuccessShow(false);
                                }}
                              >
                                No my phone did not ring
                              </Button>
                            </Grid>

                            {
                              (errorShow === true)
                                ? (
                                  <Grid item style={{ marginTop: 22, }}>
                                    Please <Link style={{ cursor: 'pointer' }} underline="none" onClick={openHelpChat}>click here</Link> for support.
                                  </Grid>
                                )
                                : (null)
                            }

                            {
                              (successShow === true)
                                ? (
                                  <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 22, }}>
                                    <Grid item>
                                      <CongratulationIcon />
                                    </Grid>

                                    <Grid item style={{ maxWidth: 400, }}>
                                      <span className={classes.textBold}>Congratulations!</span> Your test lead was successful. Click ‘Finish’ to proceed to the dashboard.
                                    </Grid>
                                  </Grid>
                                )
                                : (null)
                            }
                          </Grid>
                        )
                        : (<></>)
                    }
                  </Grid>
                </Grid>
              )
              : null
          }
        </Grid>
      </div>

      <StepsNavigation>
        <BackButton onClick={handlePrevious} />

        <FinishButton onClick={handleFinish} />
      </StepsNavigation>
    </>
  );
};

const mapDispatchToProps = { currentStep, previousStep };

export default connect(null, mapDispatchToProps)(Step4);
